import { Routes, Route } from "react-router-dom";
import EnChat from "./components/EnChat";
import ArChat from "./components/ArChat";

export default function App() {
  return (
    <Routes>
      <Route path="/" element={<ArChat />} />
      <Route path="/en" element={<EnChat />} />
    </Routes>
  );
}


// import React, { useState, useEffect } from 'react';

// const ChatComponent = () => {
//   const [socket, setSocket] = useState(null);
//   const [conversationId, setConversationId] = useState(null);
//   const [messages, setMessages] = useState([]);
//   const [userInput, setUserInput] = useState('');

//   useEffect(() => {
//     // Initialize chat on component mount
//     initializeChat();
//   }, []);

//   const initializeChat = async () => {
//     try {
//       const response = await fetch('https://qiwa-streaming.labeah.ai/api/chat/init', {
//         method: 'POST',
//         headers: {
//           'Content-Type': 'application/json',
//         },
//       });
//       const data = await response.json();
//       setConversationId(data.conversation_id);
//       connectWebSocket(data.conversation_id);
//     } catch (error) {
//       console.error('Error initializing chat:', error);
//       displayMessage("Error initializing chat. Please try again.", 'system');
//     }
//   };

//   const connectWebSocket = (conversationId) => {
//     const wsUrl = `wss://qiwa-streaming.labeah.ai/ws/chat/${conversationId}`;
//     const newSocket = new WebSocket(wsUrl);

//     newSocket.onopen = () => {
//       console.log('WebSocket connected');
//       displayMessage("WebSocket connected. You can start chatting now.", 'system');
//     };

//     newSocket.onmessage = (event) => {
//       const data = event.data;
//       if (data === '[DONE]') {
//         console.log('Response complete');
//       } else {
//         appendToLastMessage(data);
//       }
//     };

//     newSocket.onerror = (error) => {
//       console.error('WebSocket error:', error);
//       displayMessage("Error in WebSocket connection. Please try again.", 'system');
//     };

//     newSocket.onclose = (event) => {
//       console.log('WebSocket disconnected', event.reason);
//       displayMessage("WebSocket disconnected. Attempting to reconnect...", 'system');
//       setTimeout(() => connectWebSocket(conversationId), 3000); // Reconnect after delay
//     };

//     setSocket(newSocket); // Store socket in state
//   };

//   const sendMessage = async () => {
//     if (!userInput.trim()) return;

//     // Display user message
//     displayMessage(userInput, 'user');
//     setUserInput('');

//     try {
//       // Send the message through WebSocket
//       if (socket && socket.readyState === WebSocket.OPEN) {
//         socket.send(userInput);
//       } else {
//         console.error('WebSocket is not connected');
//         displayMessage("WebSocket is not connected. Please wait or refresh the page.", 'system');
//       }

//       // Placeholder for the assistant's response
//       createAssistantMessagePlaceholder();
//     } catch (error) {
//       console.error('Error sending message:', error);
//       displayMessage("Error sending message. Please try again.", 'system');
//     }
//   };

//   const displayMessage = (message, sender) => {
//     setMessages((prevMessages) => [...prevMessages, { sender, message }]);
//   };

//   const appendToLastMessage = (data) => {
//     setMessages((prevMessages) => {
//       const lastMessage = prevMessages[prevMessages.length - 1];
//       if (lastMessage.sender === 'assistant') {
//         return [
//           ...prevMessages.slice(0, prevMessages.length - 1),
//           { sender: 'assistant', message: lastMessage.message + data },
//         ];
//       }
//       return [...prevMessages, { sender: 'assistant', message: data }];
//     });
//   };

//   const createAssistantMessagePlaceholder = () => {
//     setMessages((prevMessages) => [...prevMessages, { sender: 'assistant', message: '...' }]);
//   };

//   return (
//     <div>
//       <div className="chat-window">
//         {messages.map((msg, index) => (
//           <div key={index} className={msg.sender === 'user' ? 'user-message' : 'system-message'}>
//             <strong>{msg.sender}: </strong>{msg.message}
//           </div>
//         ))}
//       </div>
//       <div className="chat-input">
//         <input
//           type="text"
//           value={userInput}
//           onChange={(e) => setUserInput(e.target.value)}
//           placeholder="Type your message..."
//         />
//         <button onClick={sendMessage}>Send</button>
//       </div>
//     </div>
//   );
// };

// export default ChatComponent;
