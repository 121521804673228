import { useState, useEffect, useRef } from "react";
import { ReactComponent as QiwaLogo } from "../assets/icons/QiwaLogo.svg";
import { ReactComponent as Stars } from "../assets/icons/Stars.svg";
import { ReactComponent as Shield } from "../assets/icons/Shield.svg";
import { ReactComponent as Like } from "../assets/icons/Like.svg";
import { ReactComponent as Dislike } from "../assets/icons/Dislike.svg";
import { ReactComponent as ChevronDown } from "../assets/icons/ChevronDown.svg";
import axios from "axios";

import QiwaAssistantImg from "../assets/images/AI.png";
import QiwaAssistantChatImg from "../assets/images/AI-chat.png";
import GradientBg from "../assets/images/Gradient.png";
import Dropdown from "./Dropdown";

export default function EnChat() {
  const suggestions = [
    {
      title: "What is Nitaqat and how is it calculated?",
    },
    {
      title: "How can I hire non-Saudi employees?",
    },
    {
      title: "How to authenticate contract?",
    },
  ];
  const [chat, setChat] = useState([]);
  const chatDivRef = useRef(null);
  const [hasChatStarted, sethasChatStarted] = useState(false);
  const [userInput, setUserInput] = useState("");
  const [conversationId, setConversationId] = useState("");

  useEffect(() => {
    chatDivRef.current?.scrollTo({
      top: chatDivRef.current.scrollHeight,
      behavior: "smooth",
    });
  }, [chat, chatDivRef]);

  // Automatically scroll to the bottom when new chat messages are added
  useEffect(() => {
    if (hasChatStarted) {
      chatDivRef.current?.scrollTo({
        top: chatDivRef.current.scrollHeight,
        behavior: "smooth",
      });
    }
  }, [hasChatStarted]);

  // Generate conversation_id when the page loads
  useEffect(() => {
    const generateConversationId = () => {
      const randomString = Math.random().toString(36).substr(2, 9);
      setConversationId(randomString);
    };

    generateConversationId();
  }, []);

  // Handle sending user message
  const handleSendMessage = async () => {
    // console.log(conversationId);
    if (userInput.trim()) {
      setChat((prevChat) => [
        ...prevChat,
        { userText: userInput, botText: "..." },
      ]);

      try {
        // Send the user's input to the API
        const response = await axios.post(
          "http://150.230.55.201:8080/api/chat",
          {
            prompt: userInput,
            conversation_id: conversationId,
          }
        );

        // Update chat with the bot's response
        const botResponse = response.data.response || "No response from bot.";
        setChat((prevChat) => [
          ...prevChat.slice(0, -1), // Remove the placeholder
          { userText: userInput, botText: botResponse },
        ]);
      } catch (error) {
        console.error("Error sending message to API:", error);
        setChat((prevChat) => [
          ...prevChat.slice(0, -1), // Remove the placeholder
          {
            userText: userInput,
            botText: "Failed to get a response. Try again.",
          },
        ]);
      }

      // Clear the input field
      setUserInput("");
    }
  };

  return (
    <div className="relative bg-[#FAFAFA] min-h-screen max-h-full">
      <div className="flex justify-between mx-8 lg:mx-12 py-6 lg:py-8 z-50">
        <div className={`flex space-x-4 `}>
          <QiwaLogo />
          <div className="flex my-auto bg-white shadow-md rounded-full py-2 px-4 z-30">
            <Stars className="text-primary my-auto" />
            <h4 className="text-primary px-1 pr-2">Siraj</h4>
          </div>
        </div>

        <div className="z-50">
          <Dropdown />
        </div>
      </div>

      {hasChatStarted ? (
        <div className="grow flex flex-col justify-between max-w-4xl mx-4 lg:mx-auto h-[77vh] lg:h-[83vh] overflow-auto">
          <div ref={chatDivRef} className="flex-grow overflow-auto">
            {chat.length > 0 ? (
              chat.map((item, index) => (
                <div
                  key={index}
                  className="bg-white rounded-2xl p-5 shadow-sm mb-5"
                >
                  <img src={QiwaAssistantChatImg} className="object-cover" />
                  <div className="mx-10">
                    <h1 className="text-2xl font-medium mb-4">
                      {item.userText}
                    </h1>
                    <p className="text-base leading-7">{item.botText}</p>

                    <div className="bg-[#F6F7F7] p-5 my-4 rounded-xl">
                      <h3 className="text-base font-medium">
                        Is this answer helpful?
                      </h3>
                      <div className="flex mt-4 space-x-5">
                        <div className="flex space-x-2">
                          <Like className="my-auto" />
                          <h5>Yes</h5>
                        </div>
                        <div className="flex space-x-2">
                          <Dislike className="my-auto" />
                          <h5>No</h5>
                        </div>
                      </div>
                    </div>

                    <div className="flex space-x-2 mb-4">
                      <ChevronDown className="my-auto" />
                      <h3 className="text-base font-medium">Sources</h3>
                    </div>
                  </div>
                </div>
              ))
            ) : (
              <div className="flex justify-center items-center text-gray-500 h-full">
                <div className="m-auto">No messages yet.</div>
              </div>
            )}
          </div>

          {/* Input field always at the bottom */}
          <div className="sticky bottom-0 bg-white flex border rounded-xl shadow-sm p-3">
            <Stars className="my-auto ml-4 text-[#6C7276]" />
            <input
              value={userInput}
              placeholder="Enter your question"
              className="grow p-3 outline-none rounded-xl"
              onKeyDown={(e) => {
                if (e.key === "Enter") {
                  handleSendMessage();
                }
              }}
              onChange={(e) => setUserInput(e.target.value)}
            />
          </div>
        </div>
      ) : (
        <>
          <img
            src={GradientBg}
            className="absolute top-0 inset-x-0 mx-auto -z-52"
          />

          <div className="max-w-7xl mx-4 lg:mx-auto">
            <div className="flex flex-col justify-center">
              <img src={QiwaAssistantImg} className="mx-auto" />
              <div className="max-w-2xl mx-auto text-center mt-5">
                <h1 className="text-2xl lg:text-3xl font-medium">Greetings</h1>
                <h2 className="text-2xl lg:text-3xl font-medium mt-1">
                  I'm your Qiwa{" "}
                  <span className="text-primary">Virtual Assistant</span>
                </h2>
                <p className="text-[#0A0E14] text-base lg:text-base mt-2.5">
                  I’m here to guide you to answers you need and help you access
                  Qiwa services. Submit your question, and I will provide the
                  information you need.
                </p>

                <div className="flex items-center bg-white mt-8 border rounded-xl shadow-sm bg-green-200 p-2">
                  <Stars className="my-auto ml-4 text-[#6C7276]" />
                  <input
                    placeholder="Enter your question"
                    className="w-full h-12 p-3 outline-none rounded-xl"
                    onKeyDown={(e) => {
                      if (e.key === "Enter") {
                        sethasChatStarted(true);
                        handleSendMessage();
                      }
                    }}
                    onChange={(e) => setUserInput(e.target.value)}
                  />
                </div>

                <div className="flex mt-2 mx-6 space-x-2 text-sm text-gray-500">
                  <Shield />
                  <h5>All data you share is secure and private.</h5>
                </div>
              </div>

              <div className="grid grid-cols-1 lg:grid-cols-3 gap-5 max-w-3xl mx-auto mt-5 lg:mt-20">
                {suggestions.map((item, idx) => {
                  return (
                    <div
                      key={idx}
                      onClick={() => {
                        setUserInput(item.title);
                        sethasChatStarted(true);
                        handleSendMessage();
                      }}
                      className="bg-white text-[#0A0E14] text-sm font-medium px-7 py-5 rounded-2xl shadow cursor-pointer"
                    >
                      {item.title}
                    </div>
                  );
                })}
              </div>
            </div>
          </div>
        </>
      )}

      <div className="lg:absolute bottom-1 inset-x-0 text-center text-[#0A0E14] text-sm py-5 lg:py-0">
        All answers are based on the official Qiwa Knowledge Center articles.
      </div>
    </div>
  );
}
