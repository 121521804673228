import { Menu, MenuButton, MenuItems, MenuItem } from "@headlessui/react";
import { ReactComponent as ChevronDown } from "../assets/icons/ChevronDown.svg";
import { useLocation, useNavigate } from "react-router-dom"; // Import useLocation

export default function Dropdown() {
  const location = useLocation(); // Get current location
  const navigate = useNavigate();
  const isEnglish = location.pathname.includes("/en"); // Check if the path contains "/"

  return (
    <div className="relative  text-right ">
      <Menu>
        <MenuButton className="rounded-md py-1.5 px-3 text-sm font-semibold ">
          <div className="flex gap-2 ">
            {isEnglish ? "Language" : "اللغة"}
            <ChevronDown className="my-auto" />
          </div>
        </MenuButton>

        <MenuItems className="absolute shadow mt-3 right-0 w-24 origin-top-right rounded-xl border border-white/5 bg-white p-1 text-sm text-black transition duration-100 ease-out">
          <MenuItem>
            {({ active }) => (
              <button
                onClick={() => navigate("/en")}
                className={`group flex w-full items-center gap-2 rounded-lg py-1.5 px-3 ${
                  isEnglish ? "bg-gray-200" : ""
                }`}
              >
                {isEnglish ? "EN" : "إنجليزي"}
              </button>
            )}
          </MenuItem>
          <MenuItem>
            {({ active }) => (
              <button
                onClick={() => navigate("/")}
                className={`group flex w-full items-center gap-2 rounded-lg py-1.5 px-3 ${
                  !isEnglish ? "bg-gray-200" : ""
                }`}
              >
                {isEnglish ? "AR" : "عربي"}
              </button>
            )}
          </MenuItem>
        </MenuItems>
      </Menu>
    </div>
  );
}
