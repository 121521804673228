import React from "react";

const PdfPreview = ({ file }) => {
  return (
    <div style={{ height: "70vh", width: "100%" }}>
      <iframe
        src={file}
        title="PDF Preview"
        width="100%"
        height="100%"
        style={{ border: "none" }}
      />
    </div>
  );
};

export default PdfPreview;
